import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import Page from "../components/project_d.js"
import IndexSPA from "../components/IndexSPA"
import { Children } from "react";

// export default Page

const Index = (props) => {
   return (
      <>
         <Helmet
          title="小Ｑ語錄 / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫"
          meta={[
            {
              name: `description`,
              content: "擔任此次計畫的策展人賴映如，因為一頭捲髮被暱稱為小Ｑ，她平時講話超有梗，張口便出 slogan",
            },
            {
              property: `og:title`,
              content: "小Ｑ語錄 / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫",
            },
            {
              property: `og:url`,
              content: 'https://online.home-to-all.com/campfires',
            },
            {
              property: `og:description`,
              content: "擔任此次計畫的策展人賴映如，因為一頭捲髮被暱稱為小Ｑ，她平時講話超有梗，張口便出 slogan",
            },
            {
              property: `og:image`,
              content: 'https://online.home-to-all.com' + require('../images/discourse_b-1.jpg')
            }
          ]}
         >
         </Helmet>
         <IndexSPA />
         {/* <Page /> */}
      </>
   )
 };
 
 // Index.propTypes = {}
 
 export default Index;